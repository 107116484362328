<template>
	<div class="chat-window">
		<div class="messages-wrapper" ref="messages">
			<ul>
				<li
					v-for="(message, index) in messages"
					:key="index"
					:class="[sameAsMode(message.user.role.name) ? 'sended' : 'received']"
				>
					<span class="text"
						>{{ message.text }}
						<span
							v-if="message.hasAttachment"
							class="download-attachment"
							@click="downloadAttachment(message.UUID)"
							><unicon name="import" :height="16" :width="16"></unicon></span
					></span>
					<span class="time">{{ message.sendTime | momentDateChat }}</span>
				</li>
			</ul>
		</div>
		<div class="file-list-wrapper">
			<div class="file-list" v-if="files.length > 0">
				<p>Anhänge</p>
				<ul>
					<li v-for="(file, index) in files" :key="index">
						{{ file.name | truncate }}
						<span class="remove-file" @click="removeFile(index)">&times;</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="create-messages">
			<form class="send-message-form" @submit.prevent="sendMessage">
				<input
					type="text"
					placeholder="Neue Nachricht"
					class="message"
					v-model="messageText"
				/>
				<input
					type="file"
					id="message-attachment"
					@change="sendFile"
					ref="fileUpload"
					multiple
					:readonly="loading"
				/>
				<label for="message-attachment" class="file-upload-icon"
					><unicon name="upload"></unicon
				></label>
				<button type="submit" class="send-message button" :disabled="loading">
					SENDEN
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import rolesMixin from '@/mixins/roles.mixin';
import formatMixin from '@/mixins/format.mixin';
import cryptoMixin from '@/mixins/crypto.mixin';
import streamSaver from 'streamsaver';
export default {
	props: {
		recordUUID: {
			type: String
		}
	},
	mixins: [rolesMixin, formatMixin, cryptoMixin],
	data() {
		return {
			messageText: '',
			files: [],
			mode: this.$store.getters.getAppMode,
			messages: [],
			iv: null,
			key: null,
			filestream: null,
			writer: null,
			loading: false,
			getNewMesagesInterval: null
		};
	},
	filters: {
		truncate: function (str) {
			const n = 35;
			return str.length > n ? str.substr(0, n - 1) + '...' : str;
		}
	},
	mounted() {
		this.initChatWindow();
	},
	beforeDestroy() {
		this.stopGetNewMessagesRequest();
	},
	methods: {
		async initChatWindow() {
			await this.getEncryptionData();
			await this.getMessages();
			this.startGetNewMessagesRequest();
		},
		async getEncryptionData() {
			this.loading = true;
			const encryptionDataResponse = await this.$api.post(
				'/messages/get_record',
				{
					recordUUID: this.recordUUID
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.iv = new Uint8Array(
				Object.values(JSON.parse(encryptionDataResponse.data.iv))
			);

			const enc_aes_key =
				this.mode === 'doctor'
					? encryptionDataResponse.data.aes_key_doc
					: this.mode === 'lab'
					? encryptionDataResponse.data.aes_key_lab
					: null;
			const private_key = await this.$store.getters.getPrivateKey;
			this.key = await this.decryptAESKey(
				this.str2ab(atob(enc_aes_key)),
				private_key
			);
			this.loading = false;
		},
		async getMessages() {
			this.loading = true;
			const messagesResponse = await this.$api.post(
				'/messages/get_messages',
				{
					recordUUID: this.recordUUID
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);

			for (let i = 0; i < messagesResponse.data.length; i++) {
				this.decryptMessageTextAndAddToList(messagesResponse.data[i]);
			}
			this.loading = false;
			this.scrollDown();
		},

		startGetNewMessagesRequest() {
			setTimeout(() => {
				this.getNewMesagesInterval = setInterval(
					this.getNewMessagesRequest,
					5000
				);
			}, 1000);
		},
		stopGetNewMessagesRequest() {
			clearInterval(this.getNewMesagesInterval);
		},
		async getNewMessagesRequest() {
			try {
				const response = await this.$api.post(
					'/messages/get_new_messages',
					{
						recordUUID: this.recordUUID
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				for (let i = 0; i < response.data.length; i++) {
					this.decryptMessageTextAndAddToList(response.data[i]);
				}
				this.scrollDown();
			} catch {
				this.stopGetNewMessagesRequest();
			}
		},
		sendFile() {
			const fileList = this.$refs.fileUpload.files;
			[...fileList].forEach((f) => {
				if (!this.files.some((file) => file.name === f.name)) {
					this.files.push(f);
				}
			});
			this.scrollDown();
		},
		async sendMessage() {
			this.loading = true;
			if (this.messageText !== '') {
				let text = await this.encryptFileAES(
					this.str2ab(this.messageText),
					this.key,
					this.iv
				);
				const sendMessageResponse = await this.$api.post(
					'/messages/send_messages',
					{
						message_text: btoa(this.ab2str(text)),
						sender: this.$store.getters.getUser.id,
						has_attachment: false,
						record_uuid: this.recordUUID
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				this.decryptMessageTextAndAddToList(sendMessageResponse.data);
			}
			if (this.files.length > 0) {
				const priv = await this.$store.getters.getPrivateKey;
				for (let i = 0; i < this.files.length; i++) {
					const file = this.files[i];

					let attachment_id = null;

					let nameEncrypted = await this.encryptFileAES(
						this.str2ab(file.name),
						this.key,
						this.iv
					);

					const arrayBuffer = await this.readFileAsync(file);
					const buffer_size = arrayBuffer.byteLength;
					const chunk_size = 1024 * 1024 * 8;
					let offset = 0;
					const chunks = Math.ceil(buffer_size / chunk_size);
					for (let i = 0; i < chunks; i++) {
						const data = arrayBuffer.slice(offset, offset + chunk_size);
						const encryptedData = await this.encryptFileAES(
							data,
							this.key,
							this.iv
						);
						const encryptedFile = {
							data: this.ab2str(encryptedData),
							type: file.type,
							name: file.name,
							name_encrypted: btoa(this.ab2str(nameEncrypted)),
							size: file.size,
							attachment_id: attachment_id,
							sender: this.$store.getters.getUser.id,
							recordUUID: this.recordUUID,
							index: i
						};
						const response = await this.$api.post(
							'/messages/add_attachment',
							encryptedFile,
							{
								headers: {
									Authorization: `Bearer ${this.$store.getters.getUserToken}`
								}
							}
						);
						response.data.message
							? this.decryptMessageTextAndAddToList(response.data.message)
							: null;
						attachment_id = response.data.attachment_id;
						offset += chunk_size;
					}
				}
			}
			this.clearFields();
			this.loading = false;
			this.scrollDown();
		},
		async decryptMessageTextAndAddToList(message) {
			let messageText = await this.decryptFileAES(
				this.iv,
				this.str2ab(atob(message.text)),
				this.key
			);
			message.text = this.ab2str(messageText);
			this.messages.push(message);
		},
		removeFile(index) {
			this.files.splice(index, 1);
		},
		clearFields() {
			this.$refs.fileUpload.files = new DataTransfer().files;
			this.files = [];
			this.messageText = '';
		},
		async downloadAttachment(messageUUID) {
			let attachmentInfoResponse = await this.$api.post(
				'/messages/get_attachment_info',
				{
					messageUUID: messageUUID
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.filestream = streamSaver.createWriteStream(
				attachmentInfoResponse.data.name,
				{
					size: attachmentInfoResponse.data.size
				}
			);
			this.writer = await this.filestream.getWriter();
			this.loadFile(attachmentInfoResponse.data.UUID);
		},
		async loadFile(UUID) {
			let that = this;
			let done = false;
			let index = 0;
			let readableStream;
			readableStream = new ReadableStream({
				start(ctrl) {
					const nextChunk = async () => {
						let fileDataResponse = await that.$api.post(
							'/messages/get_attachment',
							{
								attachment_uuid: UUID,
								chunk_index: index
							},
							{
								headers: {
									Authorization: `Bearer ${that.$store.getters.getUserToken}`
								}
							}
						);
						done =
							fileDataResponse.data.length - 1 <=
							fileDataResponse.data.current_index;
						if (fileDataResponse.data.data) {
							let data = await that.decryptData(fileDataResponse.data.data);
							ctrl.enqueue(data);
						}

						if (!done) {
							index += 1;
							nextChunk();
						} else {
							ctrl.close();
						}
					};
					nextChunk();
				}
			});
			const reader = readableStream.getReader();
			const close = () => {
				that.writer.close();
			};
			const pump = () =>
				reader.read().then((res) => {
					if (!res.done) {
						that.writer.write(new Uint8Array(res.value)).then(pump);
					} else {
						close();
					}
				});
			pump();
		},
		async decryptData(data) {
			const priv = await this.$store.getters.getPrivateKey;
			let arrayBuffer = this.str2ab(data);
			let ov = await this.decryptFileAES(this.iv, arrayBuffer, this.key);
			arrayBuffer = null;
			return ov;
		},
		base64ToArrayBuffer(base64) {
			let binaryLen = base64.length;
			let bytes = new Uint8Array(binaryLen);
			for (let i = 0; i < binaryLen; i++) {
				bytes[i] = base64.charCodeAt(i);
			}
			return bytes;
		},
		scrollDown() {
			setTimeout(() => {
				this.$refs.messages.scroll({
					top: this.$refs.messages.scrollHeight,
					behavior: 'smooth'
				});
			}, 50);
		}
	}
};
</script>

<style lang="scss">
.chat-window {
	overflow-y: hidden;
	width: 100%;
	max-height: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: minmax(auto, 85%) auto 15%;
	.messages-wrapper {
		overflow-y: scroll;
		scroll-behavior: smooth;
		scrollbar-width: thin;
		scrollbar-color: #90a4ae #fff;
		background-image: url('~@/assets/img/deltabackground.jpg');
		background-position: right;
		background-repeat: no-repeat;
		&::-webkit-scrollbar {
			width: 10px;
		}
		&::-webkit-scrollbar-track {
			background: #fff;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #90a4ae;
			border-radius: 6px;
			border: 3px solid #fff;
		}
		ul {
			display: flex;
			flex-direction: column;
			li {
				list-style-type: none;
				list-style: none;
				margin: 0.5rem 0;
				padding: 0.7rem;
				max-width: 80%;
				font-size: 0.85rem;
				&.sended {
					align-items: center;
					border-radius: 10px 10px 0px;
					background-color: #65a8c0;
					align-self: flex-end;
					color: white;
					.time {
						justify-self: end;
					}
				}
				&.received {
					border-radius: 10px 10px 10px 0px;
					background-color: #f5f5f5;
					.time {
						justify-self: flex-start;
					}
				}
				display: grid;
				grid-template-rows: 2fr 1fr;
				align-items: center;
				.time {
					font-size: 0.65rem;
				}
				.text {
					margin-bottom: 0.5rem;
					.download-attachment {
						height: 100%;
						cursor: pointer;
						.unicon {
							display: inline-flex;
						}
					}
				}
			}
		}
	}
	.file-list-wrapper {
		width: 100%;
		border-bottom: 1px solid $grey;
		.file-list {
			width: 100%;
			padding: 0.25rem 0.5rem;
			ul {
				li {
					font-size: 0.85rem;
					list-style-type: none;
					.remove-file {
						position: absolute;
						right: 1rem;
						cursor: pointer;
					}
				}
			}
		}
	}
	.create-messages {
		display: flex;
		justify-content: center;
		align-items: center;
		max-height: 10rem;
		form {
			display: grid;
			grid-template-columns: 4fr 1fr 2fr;
			width: 100%;
			padding: 1rem 0.5rem;
			.message {
				grid-column: 1;
				padding: 1.25rem;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			#message-attachment {
				display: none;
			}
			label {
				grid-column: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $grey;
				height: 100%;
				width: 100%;
				border: solid transparentize($dark-grey, 0.5);
				border-width: 0 1px 0 1px;
				.unicon {
					display: inline-flex;
				}
			}
			.send-message {
				grid-column: 3;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}
</style>
